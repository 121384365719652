import Vue from 'vue'

import './utils/element'

import '@utils/setRem';
import '@assets/styles/reset.css';
import '@assets/styles/font.css';

import App from './App.vue'
import store from '@/store'
import i18n from '@/locale'
// import '@utils/lang' 暂时注释，因为这个与vuex的lang冲突 

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  i18n
}).$mount('#app')
