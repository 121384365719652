<template>
  <div class="wrap_wrap">
    <div class="wrap">
      <div class="title">
        {{ $t("login.登录") }}
      </div>
      <el-form ref="form" :model="form">
        <el-form-item
          prop="username"
          :rules="[{ required: true, message: $t('login.请输入电话或邮箱') }]"
        >
          <el-input
            :readonly="readonly"
            @focus="readonly = false"
            v-model="form.username"
            type="text"
            :placeholder="$t('login.账号')"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="form-item-2"
          prop="password"
          :rules="[{ required: true, message: $t('login.请输入密码') }]"
        >
          <el-input
            :readonly="readonly"
            @focus="readonly = false"
            type="password"
            v-model="form.password"
            show-password
            :placeholder="$t('login.密码')"
          ></el-input>
          <div class="extra">
            <div class="extra-item" @click="findPassword">
              {{ $t("login.忘记密码") }}?
            </div>
            <div class="slide">|</div>
            <div class="extra-item" @click="onRegister">
              {{ $t("login.还未注册") }}？
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" @click="submit">
            {{ $t("login.确认") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login } from "@service/api";
import { mapState, mapActions } from "vuex";
import Message from "@utils/Message";
import { goto, parseQueryString } from "@utils/goto";
export default {
  name: "App",
  components: {},
  data() {
    return {
      readonly: true,
      form: {
        username: "",
        password: "",
      },
      nowlang: "",
      currentUrl: "",
    };
  },
  mounted() {
    this.currentUrl = window.location.hostname + "nowlang";
    //每进入一个页面先获取当前存在localStorage的语言字段nowlang
    this.nowlang = localStorage.getItem(this.currentUrl);
    //url参数改变语言存入localStorage
    const langid = window.location.href.split("lang=")[1];
    // console.log(window.location.search.split("lang="));
    if (langid) {
      localStorage.setItem(this.currentUrl, langid);
      this.nowlang = langid;
      this.setLang(this.nowlang);
      // console.log(this.nowlang);
    }
    setTimeout(() => {
      this.setLang(this.nowlang);
    }, 300);
  },
  computed: {
    ...mapState(["lang", "profile"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  watch: {
    lang: function (newV) {
      if (!this.isCN) {
        console.log(newV);
        this.nowlang = localStorage.getItem(this.currentUrl);
        this.setLang(this.nowlang);
      }
    },
  },
  methods: {
    ...mapActions(["setLang"]),
    onRegister() {
      goto("/register");
    },
    findPassword() {
      goto("/forgotpassword");
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        try {
          await login(this.form);
          const { backpage } = parseQueryString();
          const url = backpage || "/";
          goto(url, true);
        } catch (e) {
          Message.fail(e);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
// pc端
@media (min-width: $screen-md-min) {
  .wrap_wrap {
    width: 100vw;
    height: 100vh;
    @include flex-center();
  }
  .wrap {
    width: 668px;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(199, 199, 199, 0.5);
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 47px 0 76px;

    .title {
      margin-bottom: 25px;
      font-size: 35px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #000000;
    }

    .form-item-2 {
      position: relative;
      .extra {
        margin-top: 9px;
        right: 0;
        position: absolute;
        font-family: PingFangSC-Regular;
        color: #7a7a7a;
        display: flex;
        align-items: center;
        line-height: 22px;
        .slide {
          margin: 0 10px;
        }
        .extra-item {
          cursor: pointer;
          font-size: 16px;
        }
      }
    }

    .btn {
      width: 457px;
      height: 66px;
      background: #000000;
      border-radius: 11px;
      font-size: 30px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      margin-top: 47px;
    }
  }
  .wrap::v-deep {
    .el-form-item {
      margin-bottom: 30px;
    }
    .el-input__inner {
      width: 457px;
      height: 80px;
      border-radius: 11px;
      border: 1px solid #c3c3c3;
    }
  }
}
// m端
@media (max-width: $screen-md-min) {
  .wrap_wrap {
    width: 100vw;
    height: 100vh;
    @include flex-center();
    justify-content: flex-start;
    padding-top: px2rem2(200px);
    font-family: PingFang SC;
    color: #000000;
  }
  .wrap {
    width: px2rem2(700px);
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px rgba(199, 199, 199, 0.5);
    border-radius: px2rem2(30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: px2rem2(60px) 0 px2rem2(74px);

    .title {
      margin-bottom: px2rem2(42px);
      font-size: px2rem2(36px);
      font-family: PingFangSC-Regular;
      font-weight: 500;
      color: #000000;
    }

    .form-item-2 {
      position: relative;
      .extra {
        margin-top: px2rem2(20px);
        right: 0;
        position: absolute;
        z-index: 20;
        color: #7a7a7a;
        display: flex;
        align-items: center;
        .slide {
          margin: 0 px2rem2(10px);
        }
        .extra-item {
          cursor: pointer;
          font-size: px2rem2(24px);
        }
      }
    }

    .btn {
      width: px2rem2(478px);
      height: px2rem2(71px);
      background: #000000;
      border-radius: px2rem2(10px);
      font-size: px2rem2(30px);
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      margin-top: px2rem2(80px);
    }
  }
  .wrap::v-deep {
    .el-form-item {
      margin-bottom: px2rem2(34px);
    }
    .el-form-item__error {
      font-size: px2rem2(20px);
    }
    .el-input__inner {
      width: px2rem2(478px);
      height: px2rem2(83px);
      border-radius: px2rem2(10px);
      border: 1px solid #c3c3c3;
    }
  }
}
</style>
